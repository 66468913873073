class QuizForm {

    constructor() {
        this.variables();

        if($(this.form).length > 0) {
            this.bindEvents();
        }
    }

    variables() {
        this.form = '#quiz-form';
    }

    cacheDOM() {
    }

    bindEvents() {
        $(document).on('submit', this.form, this.submitForm.bind(this));
    }

    /**
     * Submit form
     * @param e
     */
    submitForm(e) {
        e.preventDefault();
        e.stopPropagation();



        let form = this.form;
        let points = this.countPoints();

        $(form + ' p.error-msg').remove();
        if(points === false) {
            $(form + " button[type=submit]").before("<p class='error-msg'>All questions are required.</p>");
            return false;
        }
        let data = this.getParams();
        data['pointsSum'] = points;
        data['titleCustomer'] = 'Ingredient Brand Fitness Check';
        data['titleAdmin'] = 'Ingredient Brand Fitness Check submission';

        $.ajax({
            url: window.location.origin + '/api/v1/braind/quiz',
            type: 'POST',
            data: data,
            success: function(response) {
                $(form).next('.thank-you-form').addClass('is-visible');
                $(form).remove();

                $('html, body').animate({
                    scrollTop: 0
                }, 500);
            },
            error: function(response) {
                $(form + " button[type=submit]").before("<p class='error-msg'>All fields are required.</p>");
            }
        });
    }

    /**
     * Get form data
     */
    getParams() {
        let params = {};
        params['questions'] = {};
        let questionId = 0;
        $.each($(this.form).find('input'), function (k, v) {
           let type = $(this).attr('type');
           let name = $(this).attr('name');
           let value = $(this).val();
           if(type !== 'radio' || (type === 'radio' && $(this).is(':checked'))) {
               if(type === 'radio') {
                   let questId = $(this).attr('id');
                   params['questions'][questionId] = $(document).find("label[for=" + questId + "]").text();
                   questionId++;
               } else {
                   params[name] = (type === 'checkbox' ? ($(this).is(':checked') ? 1 : 0) : value);
               }
           }
        });

        return params;
    }

    /**
     * Count question points
     */
    countPoints() {
        let points = 0;
        let status = 0;
        let allQuestions = $('.quiz-select-radios').length;

        $.each($(this.form).find('input[type=radio]:checked'), function (k, v) {
            let value = $(this).val();
            let attr = $(this).attr('value');

            if(typeof attr !== typeof undefined && attr !== false && value !== '' && value !== undefined) {
                points += parseInt(value);
                status++;
            }
        });

        //console.log(status, allQuestions, points);
        status = (status === allQuestions);
        return (status ? points : status);
    }


}

$(document).ready(function () {
    /************************************************
     * SUBMIT QUIZ FORM
     */
    new QuizForm();
});