// "use strict";

// /*******************************************************************************
//  * 1.0 - Front Animation
//  *******************************************************************************/

// if($(".front-mouse-anim").length) {
//     !(function ($doc, $win) {
//         var screenWidth = $win.screen.width / 2,
//             screenHeight = $win.screen.height / 2,
//             $elems = $doc.getElementsByClassName("elem"),
//             validPropertyPrefix = '',
//             otherProperty = 'perspective(1000px)',
//             elemStyle = $elems[0].style;

//         if(typeof elemStyle.webkitTransform == 'string') {
//             validPropertyPrefix = 'webkitTransform';
//         } else if (typeof elemStyle.MozTransform == 'string') {
//             validPropertyPrefix = 'MozTransform';
//         }

//         $doc.addEventListener('mousemove', function (e) {
//             var centroX = e.clientX - screenWidth,
//                 centroY = screenHeight - (e.clientY + 13),
//                 degX = centroX * 0.010,
//                 degY = centroY * 0.005,
//                 $elem

//             for (var i = 0; i < $elems.length; i++) {
//                 $elem = $elems[i];
//                 $elem.style[validPropertyPrefix] = otherProperty + 'rotateY('+ degX +'deg)  rotateX('+ degY +'deg)';
//             };
//         });
//     })(document, window);
// }

// // if($(".front-mouse-anim").length){
// // 	let root = document.documentElement;
// // 	let hotjarGraphic = document.getElementById("front-svg-anim");
// //
// // 	hotjarGraphic.addEventListener("mousemove", e => {
// // 		console.log(e);
// // 		root.style.setProperty('--mouse-x', (e.pageX - hotjarGraphic.offsetLeft) / 5 + "px");
// // 		root.style.setProperty('--mouse-y', (e.pageY - hotjarGraphic.offsetTop) / 5 + "px");
// // 	});
// // }



// function frontAnim(param) {

// 	var video = document.getElementById("bgvideo");

// 	// PLAY
// 	if (param == "play") {
// 		if (!$('.frontanim').hasClass('is-loaded')) {

// 			if (typeof(video) != 'undefined' && video != null) {
// 				//console.log("loaded");
// 				video.play();
// 			}

// 			setTimeout(function () { $('.frontanim').addClass('is-loaded'); }, 0);

// 		}
// 	}

// 	// RESET
// 	if (param == "reset") {

// 		$('.frontanim').removeClass('is-loaded');
// 		if (typeof(video) != 'undefined' && video != null) {
// 			video.currentTime = 0;
// 			video.pause();
// 		}

// 	}

// }


// $(window).resize(function () { /*code_goes_here*/ });

// $(window).load(function () {

// 	setTimeout(function () { frontAnim('play'); }, 2000);

// });

// // Scroll
// $(document).ready(function () {
// 	$(window).on('scroll', function () {

// 		let scrol_position_bottom = $(this).scrollTop() + $(this).height();
// 		let scrol_position_top = $(this).scrollTop();
// 		var window_height = $(window).height();

// 		// We go down and we reset the front animation
// 		if (scrol_position_bottom > (window_height * 2)) {
// 			//console.log("Down");
// 			frontAnim('reset');
// 		}

// 		// We move up, and we see front section
// 		if (scrol_position_top < (window_height / 3)) {
// 			//console.log("Up");
// 			frontAnim('play');
// 		}


// 	});
// });


// /*******************************************************************************
//  * 2.0 - SVG circle animation
//  *******************************************************************************/

// $('#svgexpand .circle').each(function (index, el) {


// 	$(this).on('mouseenter', function () {

// 		if( $( window ).width() > 767 ) {
//             var i = $(this).index();
//             var selectedElem = $('.chart-hover-content').eq(i - 1);

//             selectedElem.addClass('is-visible-hover');
// 		}
// 	});

// 	$(this).on('mouseleave', function () {

//         if( $( window ).width() > 767 ) {
//             var i = $(this).index();
//             var selectedElem = $('.chart-hover-content').eq(i - 1);

//             selectedElem.removeClass('is-visible-hover');
//         }
// 	});


// 	$(this).on('click', function () {

// 		if($( window ).width() < 767 ) {
//             var i = $(this).index();
//             var selectedElem = $('.chart-hover-content').eq(i - 1);

//             if (selectedElem.hasClass('is-visible')) {
//                 selectedElem.removeClass('is-visible');
//             }
//             else {
//                 $('.chart-hover-content').removeClass('is-visible');
//                 selectedElem.addClass('is-visible');
//             }
// 		}

// 	});

// });

// // Close circle descriptions
// $(document).mouseup(function (e) {
// 	$('.chart-hover-content').each(function () {
// 		if (!$(this).is(e.target) && $(this).has(e.target).length === 0) {
// 			$(this).removeClass('is-visible');
// 		}
// 	});
// });


// $(window).load(function () { /*code_goes_here*/ });


// // Show wave animation when SVG is visible in viewport
// $(window).scroll(function () {
// 	if ($('#svgexpand').length) {


// 		var top_of_element = $('#svgexpand').offset().top;
// 		var bottom_of_element = $('#svgexpand').offset().top + ($('#svgexpand').outerHeight() / 2);
// 		var bottom_of_screen = $(window).scrollTop() + (window.innerHeight / 2);
// 		var top_of_screen = $(window).scrollTop();

// 		if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {

// 			if (!$('.svg-expand-container').hasClass('anim-run')) {
// 				setTimeout(function () {
// 					$('.svg-expand-container').addClass('anim');
// 					$('.svg-expand-container').addClass('anim-run');
// 				}, 500);
// 			}

// 		}
// 		else {
// 			$('.svg-expand-container').removeClass('anim');
// 		}

// 	}
// });



// /*******************************************************************************
//  * 3.0 - QUIZ TOGGLERS
//  *******************************************************************************/


// (function ($) {

// 	$.fn.threestatebutton = function (params) {
// 		return this.each(function () {

// 			// Create Wrapper
// 			var casingWrapper = $(document.createElement("div")).addClass("tsb-casing-wrapper");

// 			// Create background
// 			var casing = $(document.createElement("div"))
// 				.addClass("tsb-casing")
// 				//.css("height", params.statecount + "em")
// 				.append($(document.createElement("span"))
// 					.addClass("tsb-ball")
// 					.css("top", params.state + "em")
// 					.attr("data-tbsstate", params.state)
// 				);

// 			// Create radio button wrapper
// 			var buttonWrapper = $(document.createElement("div")).addClass("quiz-select-radios");

// 			var source = $(this);
// 			source.append(casingWrapper);
// 			source.find('.tsb-casing-wrapper').append(casing);
// 			source.append(buttonWrapper);

// 			var qsOption = source.attr('qsOption');

// 			for (var i = 0; i < params.statecount; i++) {

// 				let label = source.find('.quiz-select-labels label').eq(i);

// 				// Append points
// 				casing.append($(document.createElement("span"))
// 					.addClass("tsb-state")
// 					.attr("data-tbsstate", i)
// 					.css("top", i + "em")
// 				);

// 				// Appends radio buttons
// 				buttonWrapper.append($(document.createElement("input"))
// 					.addClass("tsb-radio")
// 					.attr({
// 						type: 'radio',
// 						name: qsOption,
// 						value: label.attr('qsPoints'),
// 						id: qsOption + "_" + (i + 1)
// 					})
// 				);

// 				// Link labels with radio buttons
// 				label.attr('for', qsOption + "_" + (i + 1));

// 			}

// 			// Switcher click event
// 			casing.children(".tsb-state").click(function () {
// 				var newpos = $(this).css("top");
// 				var state = $(this).attr("data-tbsstate");
// 				var oldstate = $(this).siblings(".tsb-ball").attr("data-tbsstate");
// 				if (state !== oldstate) {
// 					$(this).siblings(".tsb-ball").animate({top: newpos}, 0, function () {
// 						$(this).attr("data-tbsstate", state);
// 						params.stateChanged(state, source);
// 					});
// 				}

// 			});


// 		});

// 	};

// }(jQuery));


// // Quiz select - build toggle switches
// $('.quiz-select').each(function (index, el) {

// 	var numStates = $(this).find('.quiz-select-labels label').length; // Get number os states based on number of labels
// 	var activeState = $(this).attr('qsActive'); // Get which state should be active
// 	activeState = activeState - 1;

// 	// $(this).find('label').eq(activeState).addClass('is-active'); // Set active label

// 	// STATE: Init
// 	$(this).threestatebutton({
// 		statecount: numStates,
// 		state: activeState,
// 		stateChanged: function (state, source) {
// 			//source.find('label').removeClass('is-active');
// 			//source.find('label').eq(state).addClass('is-active');
// 		}
// 	});

// 	// Quiz select - set initial positions
// 	$(this).find('label').each(function (index, el) {

// 		var thisParent = $(this).parentsUntil('.quiz-select').parent();

// 		var thisPosMargin = parseInt($(this).css('margin-top'));
// 		var thisPosLineHeight = parseInt($(this).css('line-height'));
// 		var thisPos = $(this).position().top + thisPosMargin + (thisPosLineHeight / 2) - 10;

// 		thisParent.find('.tsb-casing .tsb-state').eq(index).css("top", thisPos);


// 		// Set initial positions and toggles
// 		if (index === activeState) {
// 			//thisParent.find('.tsb-casing .tsb-ball').hide();
// 			// thisParent.find('.tsb-casing .tsb-ball').css("top", thisPos);
// 			// thisParent.find('label').removeClass('is-active');
// 			// thisParent.find('label').eq(activeState).addClass('is-active');
// 			//
// 			// thisParent.find('input[type="radio"]').eq(activeState).prop('checked', true);
// 		}


// 		// Click event
// 		$(this).on('click', function () {
// 			// console.log(thisPos);
// 			// thisParent.find('.tsb-casing .tsb-ball').css("top", thisPos).addClass('tsb-ball--anim');
// 			// thisParent.find('label').removeClass('is-active');
// 			// thisParent.find('label').eq(index).addClass('is-active');
// 			// thisParent.find('input[type="radio"]').prop('checked', false);
// 			// thisParent.find('input[type="radio"]').eq(index).prop('checked', true);
// 		});


// 	});



// 	//Quiz select - Click on Ball
// 	$(this).find('.tsb-casing .tsb-state').each(function(index, el) {
// 		$(this).on('click', function () {
// 			var itemPos = parseInt($(this).index()-1);
// 			var parent = $(this).parents('div.quiz-select');
// 			parent.find('.quiz-select-labels label').eq(itemPos).trigger('click');

// 		});
// 	});

// 	$.fn.matchHeight._update();

// });


// // Click event
// $('.quiz-select label').each(function (index, el) {
// 	$(this).on('click', function () {

// 		var thisParent = $(this).parentsUntil('.quiz-select').parent();

// 		var thisPosMargin = parseInt($(this).css('margin-top'));
// 		var thisPosLineHeight = parseInt($(this).css('line-height'));
// 		var thisPos = $(this).position().top + thisPosMargin + (thisPosLineHeight / 2) - 10;

// 		thisParent.find('.tsb-casing .tsb-ball').css("top", thisPos).addClass('tsb-ball--anim');

// 		thisParent.find('label').removeClass('is-active');
// 		$(this).addClass('is-active');
// 		thisParent.find('input[type="radio"]').prop('checked', false);
// 		thisParent.find('input[type="radio"]').eq(index).prop('checked', true);

// 	});
// });


// function repositionBalls() {

// 	$('.quiz-select').each(function (index, el) {
// 		$(this).find('label').each(function (index, el) {

// 			var thisParent = $(this).parentsUntil('.quiz-select').parent();

// 			var thisPosMargin = parseInt($(this).css('margin-top'));
// 			var thisPosLineHeight = parseInt($(this).css('line-height'));
// 			var thisPos = $(this).position().top + thisPosMargin + (thisPosLineHeight / 2) - 10;

// 			thisParent.find('.tsb-casing .tsb-state').eq(index).css("top", thisPos);

// 			// Active label also sets the active ball
// 			if ( $(this).hasClass('is-active') ) {
// 				thisParent.find('.tsb-casing .tsb-ball').css("top", thisPos);
// 			}

// 		});
// 	});

// }


// $(window).resize(function () {
// 	repositionBalls();
// });


// /*******************************************************************************
//  * 4.0 - QUIZ POINTS AND LINES
//  *******************************************************************************/

// // Vars
// var lineWidth = 10;
// var t = 1;
// var points;
// var vertices = [];
// var atForm = true;
// var fromForm = true;
// var animationRan = false;
// var transitionEvent = whichTransitionEvent();



// // IF canvas exists
//  if ($('#quiz-canvas').length > 0) {
// 	 //const ctx = document.querySelector("#quiz-canvas").getContext("2d");
// 	 var canvas = document.getElementById("quiz-canvas");
// 	 var ctx = canvas.getContext("2d");

// 	 //console.log("canvas loaded");

// 	 $(window).load(function () {
// 		resizeCanvasToDisplaySize(ctx.canvas);
// 		quizLineAnim();
// 		quizFormAnim();
// 		redrawLine();
// 	 });


// 	 $(window).scroll(function () {
// 		//resizeCanvasToDisplaySize(ctx.canvas);
// 		quizLineAnim();
// 		quizFormAnim();
// 	 });


// 	 $(window).resize(function () {
// 		resizeCanvasToDisplaySize(ctx.canvas);
// 		quizFormAnim();
// 		quizLineAnim();
// 		redrawLine();
// 		drawQuizCanvas(true);
// 	 });
//  }






// // Quit line draw on scroll down
// function quizLineAnim() {

// 	var scroll = $(window).scrollTop();


// 	// FORM:
// 	var targetElem = $('.quiz-section--intro .line-start');

// 	if(targetElem.length) {

// 		var targetElemHeight = targetElem.outerHeight() / 2;
// 		var targetElemTopOffset = targetElem.offset().top;
// 		var sectionHeight = $('.quiz-section--intro').outerHeight() / 2;
// 		var targetElemFromTop = targetElemTopOffset - sectionHeight; // When target is on middle of screen


// 		// Half of the element is visible on screen
// 		if (scroll >= targetElemFromTop  ) {
// 			targetElem.addClass('is-drawing');


// 			var current_scroll_top = $(document).scrollTop();
// 			var scroll_delta = current_scroll_top - targetElemFromTop;

// 			// Draw the line until we get to the top of form, then we stop
// 			if ( !$('.line-start').hasClass('anim-end') ) {
// 				targetElem.css('height', scroll_delta);
// 			}



// 		} else {
// 			if ( !$('.line-start').hasClass('anim-end') ) {
// 				targetElem.removeClass('is-drawing');
// 			}
// 		}

// 	}

// }

// // Redraw line on resize
// function redrawLine() {

// 	var targetElem = $('.quiz-section--intro .line-start');

// 	if(targetElem.length && targetElem.hasClass('anim-end')) {

// 		var target = $('.quiz-section--form');
// 		var targetHeight = target.outerHeight() / 2;
// 		var lineHeight = target.offset().top;

// 		targetElem.css('height', lineHeight);
// 		targetElem.addClass('noanim');

// 		drawQuizCanvas(true);

// 	}

// }



// // Form animation line
// function quizFormAnim() {


// 	var scroll = $(window).scrollTop();
// 	var wH = $(window).height();
// 	scroll = scroll - (wH / 2);


// 	// FORM:
// 	var targetElem = $('.quiz-section--form');
// 	var targetElemHeight = targetElem.outerHeight();
// 	var targetElemTopOffset = targetElem.offset().top;

// 	var thicCalc = targetElemTopOffset - wH;

// 	// Half of the element is visible on screen
// 	if (scroll >= thicCalc) {
// 		//console.log("tle smo");
// 		if (!targetElem.hasClass('is-animated') && !$('.line-start').hasClass('anim-end')) {
// 			targetElem.addClass('is-animated');
// 			$('.line-start').addClass('anim-end');


// 			if (atForm) {
// 				atForm = false;

// 				// Smo pri formi, vendar moramo preverit ali scrollamo od vrha dol ali pa smo že naložili page in smo na dnu
// 				// Zračunamo ali se črta dotika forme

// 				//console.log("tle smo");
// 				//console.log(animationRan);

// 				// if ( targetElem.hasClass('is-animated') ) {
// 				// 	//console.log("tle smo 2");
// 				// 	animationRan = true;
// 				// 		drawQuizCanvas();
// 				// }

// 				$('.line-start').on(transitionEvent, function (event) {


// 					if ( targetElem.hasClass('is-animated') && $('.line-start').hasClass('anim-end') && animationRan == false) {

// 						//console.log("down end");
// 						//console.log(animationRan);

// 						animationRan = true;
// 						drawQuizCanvas();

// 					}

// 				});
// 			}


// 		}

// 	}


// }







// // Draw on Canvas
// function drawQuizCanvas(redraw) {

// 	canvasRestore();

// 	// POINT 1 : Starting point
// 	var point1_x_offset = 0;
// 	var point1_x = $('.quiz-section--intro .line-start').offset().left + (lineWidth / 2) + point1_x_offset;
// 	var point1_y = 0;

// 	// POINT 2 : Form top (with offset) - divider sets position when the line turns down
// 	if ( $( window ).width() > 767 ){ var form_divider = 4; }
// 	else { var form_divider = 2.5; }
// 	var point2_offset = 40;
// 	var point2_x = $(".quiz-section--form .quiz-form").offset().left + ( $(".quiz-section--form .quiz-form").outerWidth() / form_divider ); // One fourth  of the form width
// 	var point2_y = $(".quiz-section--form .quiz-form").offset().top - $(".quiz-section--form").offset().top + parseInt($(".quiz-section--form .quiz-form").css('padding-top')) - point2_offset;

// 	// POINT 3 : Submit button
// 	var point3_offset = 0;
// 	var point3_x = $('.quiz-section--form .quiz-form button').offset().left;
// 	var point3_y = $(".quiz-section--form .quiz-form button").offset().top - $(".quiz-section--form").offset().top + ($(".quiz-section--form .quiz-form button").outerHeight() / 2) - point3_offset;

// 	// First draw: Animation with multiple waypoints
// 	if (redraw !== true) {

// 		// FORWARDS
// 		vertices.push({
// 			x:point1_x,
// 			y:point1_y
// 		});

// 		vertices.push({
// 			x:point1_x,
// 			y:point2_y
// 		});

// 		vertices.push({
// 			x:point2_x,
// 			y:point2_y
// 		});

// 		vertices.push({
// 			x:point2_x,
// 			y:point3_y
// 		});

// 		vertices.push({
// 			x:point3_x,
// 			y:point3_y
// 		});

// 		// GO ANIMATE THIS
// 		points = calcWaypoints(vertices, 60);
// 		animateFwd();

// 		//console.log('canvas first animation');

// 	// Redraw: quick line draw when we resize and reset canvas
// 	} else {

// 		// Only if line has completed drawing
// 		if ( $('.line-start').hasClass('anim-end') ) {

// 			ctx.strokeStyle = "#faaa38";
// 			ctx.fillStyle = "#faaa38";
// 			ctx.lineCap = "square";
// 			ctx.lineWidth=10;

// 			ctx.beginPath();
// 			ctx.moveTo(point1_x, point1_y);
// 			ctx.lineTo(point1_x, point2_y);
// 			ctx.lineTo(point2_x, point2_y);
// 			ctx.lineTo(point2_x, point3_y);
// 			ctx.lineTo(point3_x, point3_y);
// 			ctx.stroke();
// 			ctx.closePath();

// 			//console.log('canvas redrawn');

// 		}

// 	}


// }



// // Clear Canvas
// function canvasRestore() {
// 	//ctx.fillStyle = "#000000";
// 	//ctx.fillRect(0, 0, canvas.width, canvas.height);

// 	window.cancelAnimationFrame(animateFwd);

// 	// ctx.fillStyle = "#3f6a78";
// 	// ctx.moveTo(0, 0);
// 	// ctx.fillRect(0, 0, canvas.width, canvas.height);

// 	// ctx.restore();

// 	//ctx.clearRect(0, 0, canvas.width, canvas.height);
// 	//console.log('canvas restored');



// 	// Store the current transformation matrix
// 	ctx.save();

// 	// Use the identity matrix while clearing the canvas
// 	ctx.setTransform(1, 0, 0, 1, 0, 0);
// 	ctx.clearRect(0, 0, canvas.width, canvas.height);

// 	// Restore the transform
// 	ctx.restore();

// }


// // Calc waypoints traveling along vertices
// function calcWaypoints(vertices,speed) {

// 	if (speed == "undefined") { var speed = 100; }

// 	var waypoints = [];
// 	for (var i = 1; i < vertices.length; i++) {
// 		var pt0 = vertices[i - 1];
// 		var pt1 = vertices[i];
// 		var dx = pt1.x - pt0.x;
// 		var dy = pt1.y - pt0.y;
// 		for (var j = 0; j < speed; j++) {
// 			var x = pt0.x + dx * j / speed;
// 			var y = pt0.y + dy * j / speed;
// 			waypoints.push({
// 				x: x,
// 				y: y
// 			});
// 		}
// 	}
// 	return (waypoints);

// }


// // Animating canvas
// function animateFwd() {

// 	if (t < points.length - 1) { requestAnimationFrame(animateFwd); }
// 	else {
// 		drawQuizCanvas(true);
// 		//console.log("animation finished");
// 	}

// 	ctx.strokeStyle = "#faaa38";
// 	ctx.fillStyle = "#faaa38";
// 	ctx.lineCap = "square";
// 	ctx.lineWidth=10;

// 	ctx.beginPath();
// 	ctx.moveTo(points[t - 1].x, points[t - 1].y);
// 	ctx.lineTo(points[t].x, points[t].y);
// 	ctx.stroke();
// 	ctx.closePath();

// 	// increment "t" to get the next waypoint
// 	t++;
// }


// // Canvas resize
// function resizeCanvasToDisplaySize(canvas) {
//    // look up the size the canvas is being displayed
//    const width = canvas.clientWidth;
//    const height = canvas.clientHeight;

// 	//console.log('resize');
// 	//setTimeout(drawQuizCanvas(), 200);

//    // If it's resolution does not match change it
//    if (canvas.width !== width || canvas.height !== height) {
// 	 canvas.width = width;
// 	 canvas.height = height;
// 	return true;
//    }

//    return false;
// }



// // Auto select supported transitionend event ... with prefix or without
//  function whichTransitionEvent(){
//    var t,
//        el = document.createElement("fakeelement");

//    var transitions = {
//      "transition"      : "transitionend",
//      "OTransition"     : "oTransitionEnd",
//      "MozTransition"   : "transitionend",
//      "WebkitTransition": "webkitTransitionEnd"
//    }

//    for (t in transitions){
//      if (el.style[t] !== undefined){
//        return transitions[t];
//      }
//    }
//  }
