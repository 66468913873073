

// function initSharer()
// {
//     $(".blog-block .share-list.first").each(function () {
//         $(this).specto_sharer({
//             //ICONS
//             fa_icons: false, //async load font awesome and add classes to share icons - if html is rendered by plugin, this is set to true
//             force_no_fa_icons: true, //force no font awesome - if html is rendered by plugin
//             // icons: ["share-fb", "share-tw", "share-lin", "share-mail"], //array of icon names availible (ordered) - valid only if html is rendered by plugin

//             //CONTENT
//             //string or function, which translates to any css selector (!string!)
//             elm_title: ".share_title", //css selector for title
//             elm_description: ".share_description", //css selector for description
//             elm_caption: ".share_description", //css selector for caption / twitter title
//             elm_url_ref: ".share_link", //css selector for element with url - if not present url is sent of current page
//             elm_image: "figure img",
//             // elm_url_ref_attr: "src", //share link attribute

//             //POPUP
//             popup_linkedin: true, //is linkedin share in popup window
//             popup_mail: true, //is mail share in popup window
//             popup_gplus: false, //is gplus share in popup window
//             popup_twitter: true, //is twitter share in popup window
//             popup_width: "600", //default width of popup
//             popup_height: "500", //default height of popup

//             //FB settings
//             appId: "190848295138380", //Facebook appId
//             version: "v3.0", //Facebook app version
//             new_version: true, //Facebook app is new version
//             has_fb: true, //has facebook sharer (to async load fb sdk) -> by default is false

//         });
//         $(this).removeClass('first');
//     });

// }

function initSharerSingle()
{
    $(".blog-inside-section .blog-inside-content-area .share-list").each(function () {
        $(this).specto_sharer({
            //ICONS
            fa_icons: false, //async load font awesome and add classes to share icons - if html is rendered by plugin, this is set to true
            force_no_fa_icons: true, //force no font awesome - if html is rendered by plugin
            // icons: ["share-fb", "share-tw", "share-lin", "share-mail"], //array of icon names availible (ordered) - valid only if html is rendered by plugin

            //CONTENT
            //string or function, which translates to any css selector (!string!)
            elm_title: ".share_title", //css selector for title
            elm_description: ".share_description", //css selector for description
            elm_caption: ".share_description", //css selector for caption / twitter title
            elm_url_ref: ".share_link", //css selector for element with url - if not present url is sent of current page
            elm_image: "figure img",
            // elm_url_ref_attr: "src", //share link attribute

            //POPUP
            popup_linkedin: true, //is linkedin share in popup window
            popup_mail: true, //is mail share in popup window
            popup_gplus: false, //is gplus share in popup window
            popup_twitter: true, //is twitter share in popup window
            popup_width: "600", //default width of popup
            popup_height: "500", //default height of popup

            //FB settings
            appId: "190848295138380", //Facebook appId
            version: "v3.0", //Facebook app version
            new_version: true, //Facebook app is new version
            has_fb: true, //has facebook sharer (to async load fb sdk) -> by default is false
        });
    });
}
