// window.addEventListener('DOMContentLoaded', () => {

// 	const observer = new IntersectionObserver(entries => {
// 		entries.forEach(entry => {
// 			const id = entry.target.getAttribute('id');
// 			if (entry.intersectionRatio > 0) {
// 				document.querySelector(`.sidebar--landing__anchors li a[href="#${id}"]`).parentElement.classList.add('is-active');
// 			} else {
// 				document.querySelector(`.sidebar--landing__anchors li a[href="#${id}"]`).parentElement.classList.remove('is-active');
// 			}
// 		});
// 	});

// 	// Track all sections that have an `id` applied
// 	document.querySelectorAll('h2[id]').forEach((section) => {
// 		observer.observe(section);
// 	});

// });

$(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();

    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top - parseInt(header_height() + 20)
    }, 500);
});
