

$(function () {
    // TIMELINE SLIDER
    if ($('.brand-timeline-slider-init').length) {
        timeline_slier();
    }

    // TESTIMONIALS SLIDER
    if ($('.testimonials-slider-init').length) {
        testimonials_slider();
    }

    // TESTIMONIALS SLIDER
    if ($('.testimonials-slider-landing-init').length) {
        testimonials_slider_landing();
    }

    // Parallax Image
    if ($('.parallax-img').length) {
        if (!is_touch_device()) {
            $('.parallax-img').jarallax({
                speed: 0.2
            });
        }
    }

    //NAVIGATION
    $('.navigation-trigger').on('click', function () {
        $('body').toggleClass('navigation--open');
    });

    // SITE LOADER
    setTimeout(function () {
        $('.loader-wrapper').fadeOut(300);
        $('body').addClass('page--loaded');
    }, 10000);

    // Full Height Section
    $('.full-height-section').css('min-height', win_height());

    // SIDEBAR ACCORDION
    $('.sidebar-block h3').on('click', function () {
        if (win_width() < 992) {
//            var sidebar_offset = $(this).parents('.sidebar-block').offset().top;
            $('.sidebar-block').not($(this).parents('.sidebar-block')).removeClass('sidebar--open').find('.sidebar-collapsible-area').slideUp('fast');
            $(this).parents('.sidebar-block').toggleClass('sidebar--open').find('.sidebar-collapsible-area').slideToggle('fast');
//            $('html, body').animate({
//                scrollTop: sidebar_offset - header_height()
//            }, 1000);
        }
    });

    //BLOG HOVER
    $('.blog-block figure,.blog-block h2,.blog-block .read-more').mouseenter(function () {
        $(this).parents('.blog-block').addClass('blog-block--hovered');
    }).mouseleave(function () {
        $('.blog-block').removeClass('blog-block--hovered');
    });

    // SCROLL TO DIV
    // $('.scroll').bind('click', function (event) {
    //     var anchor = $(this);
    //     $('html, body').stop().animate({
    //         scrollTop: ($(anchor.attr('href')).offset().top - header_height())
    //     }, 600);
    //     event.preventDefault();
    // });

    // KNOWLEDGE MESONERY
    // $('.grid').isotope({
    //     itemSelector: '.grid-item',
    //     percentPosition: true,
    //     masonry: {
    //         columnWidth: '.grid-sizer'
    //     }
    // })
});

// $(window).resize(function () {
//     // Full Height Section
//     $('.full-height-section').css('min-height', win_height());
// });

$(window).on('load',function () {

    new SpectoForm();

    setTimeout(function () {
        $('.brand-fitness-block').addClass('visible');
    }, 2000);

    //SITE LOADER
    $('.loader-wrapper').fadeOut(300);
    $('body').addClass('page--loaded');

});

function win_height() {
    return $(window).outerHeight();
}
function win_width() {
    return $(window).width();
}
function header_height() {
    return $('header').outerHeight();
}

// STICKY HEADER
var sticky = $('header');
if ($('.front-page').length) {
    $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        // Fixed Menu
        if (scroll >= 280) {
            sticky.addClass('fixed');

        } else {
            sticky.removeClass('fixed');
        }
    });
}

if( $( window ).width() < 1180 ){
    var showButton = $(".brand-fitness-block");

    if ($('.front-page').length) {
        $(window).scroll(function () {
            var scroll = $(window).scrollTop();
            // Fixed Menu
            if (scroll >= 280) {
                showButton.addClass("show");

            } else {
                showButton.removeClass("show");
            }
        });
    }
}

//TIMELINE SLIDER
function timeline_slier() {
    var item_count = parseInt($('.brand-timeline-slider-init').find('.brand-timeline-slide').length);
    $('.brand-timeline-slider-init.owl-carousel').owlCarousel({
        items: 4,
        rtl:true,
        navText: ['', ''],
        onInitialize: function () {
            if (item_count <= 4) {
                this.options.nav = false;
            } else {
                this.options.nav = true;
            }
        },
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1200: {
                items: 4
            }
        }
    });
}

// TESTIMONIALS SLDIER
function testimonials_slider() {
    var item_count = parseInt($('.testimonials-slider-init').find('.quote-block').length);
    $('.testimonials-slider-init.owl-carousel').owlCarousel({
        items: 3,
        touchDrag: false,
        mouseDrag: false,
        navText: ['', ''],
        onInitialize: function () {
            if (item_count <= 3) {
                this.options.dots = false;
                this.options.nav = false;
            } else {
                this.options.dots = true;
                this.options.nav = true;
            }
        },
        responsive: {
            0: {
                items: 1,
                dots: true,
                touchDrag: true,
                mouseDrag: true,
            },
            600: {
                items: 2,
                dots: true,
                touchDrag: true,
                mouseDrag: true,
            },
            992: {
                items: 3,
                dots: false,
                touchDrag: false,
                mouseDrag: false,
            }
        }
    });
}


// TESTIMONIALS SLDIER LANDING
function testimonials_slider_landing() {
    var item_count = parseInt($('.testimonials-slider-landing-init').find('.quote-block').length);
    $('.testimonials-slider-landing-init.owl-carousel').owlCarousel({
        items: 2,
        touchDrag: false,
        mouseDrag: false,
        navText: ['', ''],
        onInitialize: function () {
            if (item_count <= 3) {
                this.options.dots = false;
                this.options.nav = false;
            } else {
                this.options.dots = true;
                this.options.nav = true;
            }
        },
        responsive: {
            0: {
                items: 1,
                dots: true,
                touchDrag: true,
                mouseDrag: true,
            },
            600: {
                items: 2,
                dots: true,
                touchDrag: true,
                mouseDrag: true,
            }
        }
    });
}

// DOCUMENT CLICK
$(document).on("touchstart click", function (e) {
    var container = $('.navigation-trigger');
    var contactInfo = $('.navigation a');

    if (!container.is(e.target) // if the target of the click isn't the container...
            && container.has(e.target).length === 0 && !contactInfo.is(e.target) && contactInfo.has(e.target).length === 0)
    {
        // Yor action is here
        $('body').removeClass('navigation--open');
    }
});

function is_touch_device() {
    return 'ontouchstart' in window
            || navigator.maxTouchPoints;
}
;

// Specto faker
$(document).ready(function(){
    $(".lang-dropdown select").specto_faker({
        on_change: function(newVal, jsEvent){ window.location.href = newVal; }, //callback function after value has changed //e.g. function(newVal, jsEvent){}
        //animated: true
    });

    $(function() {
        $('.equal-height').matchHeight({
            byRow: true,
            property: 'height',
            target: null,
            remove: false
        });
    });

    // if ( $( window ).width() < 767 ){
    //     $(function() {
    //         $('.equal-height-front-mobile').matchHeight({
    //             byRow: true,
    //             property: 'height',
    //             target: null,
    //             remove: false
    //         });
    //     });

    //     $(function() {
    //         $('.equal-height-front-mobile-box').matchHeight({
    //             byRow: true,
    //             property: 'height',
    //             target: null,
    //             remove: false
    //         });
    //     });
    // }


    // Quiz notice
    $(".brand-fitness-block").click(function (){
        console.log("set");
        setCookie('quiz_seen', "YES", 1000);
    });
    var quiz_seen = getCookie('quiz_seen');
    if(quiz_seen !== 'YES'){
        $(".brand-fitness-block").removeClass('hide');
    }



    // Cookies
    $("#span-cookies-agree").click(function (){
        setCookie('cookie_agreement', "YES", 1000);
        closeCookies();
    });
    $("#span-cookies-reject").click(function (){
        setCookie('cookie_agreement', "NO", 1);
        closeCookies();
    });
    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + "; " + expires;
    }
    function getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length == 2) return parts.pop().split(";").shift();
    }
    function closeCookies() {
        $("#div-cookie").animate({"opacity": 0 }, 300);
        setTimeout(function(){
            $("#div-cookie").toggleClass("popup-off");
        }, 500);
    }
    var cookie_agreement = getCookie('cookie_agreement');
    if(cookie_agreement !== 'YES'){
        $("#div-cookie").removeClass('hide');
    }


    pressLoadMore();
    //initSharer();
    initSharerSingle();
});

function pressLoadMore(){
    //AJAX FOR LOAD MORE
    let that = this;

    $(".braind-load-more .btn").each(function(){
        $(this).on("click", function(e){

            e.preventDefault();

            var urlMore = $(this).attr("href");

            let pageID = $('body').data('pageid');
            let widgetID = 1;
            let widgetType = "news";
            let catID = 12;
            if($('html').attr('lang') === 'de')
                catID = 54;
            else if($('html').attr('lang') === 'sl')
                catID = 32;

            // let paginateNumber = 6;

            // $(".braind-load-more").remove();

            $.get(urlMore, {pageID, widgetID, widgetType, catID}, function(data){


                var nextNewsHtml = $("<div>" + data.data + "</div>");

                nextNewsHtml.find(".col-xs-6").each(function(int){
                    $(".blog-list-wrapper .row .col-xs-6").eq(int).append($(this).html());
                });

                if(nextNewsHtml.find(".braind-load-more a").length){
                    $(".braind-load-more a").attr("href", nextNewsHtml.find(".braind-load-more a").attr("href"));
                }else{
                    $(".braind-load-more").remove();
                }


                $(".blog-block .share-list.first").specto_sharer({
                    //ICONS
                    fa_icons: false, //async load font awesome and add classes to share icons - if html is rendered by plugin, this is set to true
                    force_no_fa_icons: true, //force no font awesome - if html is rendered by plugin
                    icons: ["share-fb", "share-tw", "share-lin", "share-mail"], //array of icon names availible (ordered) - valid only if html is rendered by plugin

                    //CONTENT
                    //string or function, which translates to any css selector (!string!)
                    elm_title: ".share_title", //css selector for title
                    elm_description: ".share_description", //css selector for description
                    elm_caption: ".share_description", //css selector for caption / twitter title
                    elm_url_ref: ".share_link", //css selector for element with url - if not present url is sent of current page
                    elm_image: "figure img",
                    // elm_url_ref_attr: "http://poslo.si/25let/25let-seznam", //share link attribute

                    //POPUP
                    popup_linkedin: true, //is linkedin share in popup window
                    popup_mail: true, //is mail share in popup window
                    popup_gplus: false, //is gplus share in popup window
                    popup_twitter: true, //is twitter share in popup window
                    popup_width: "600", //default width of popup
                    popup_height: "500", //default height of popup

                    //FB settings
                    appId: "190848295138380", //Facebook appId
                    version: "v3.0", //Facebook app version
                    new_version: true, //Facebook app is new version
                    has_fb: true, //has facebook sharer (to async load fb sdk) -> by default is false

                });
                $(".blog-block .share-list.first").removeClass('first');



            });
        }) ;
    });
}
