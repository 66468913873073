class SpectoForm {

    constructor(settings = '') {
        this.forms = 'form[data-specto-form]';
        if(!settings.length) {
            this.setSettings(settings);
        }

        this.cacheDOM();
        this.bindEvents();
        this.onLoad();
    }

    setSettings(settings) {
        $.each(settings, (key, val) => {
            if(this.hasOwnProperty(key) || this.hasOwnProperty('$'+key)) {
                this[key] = val;
            }
        });
    }

    cacheDOM() {
    }

    bindEvents() {
        $(document).on('submit', this.forms, this.submitForm.bind(this));
    }

    onLoad() {
        SpectoForm.ajaxLoad();
        // toggle checkbox - knowledge form
        $('#chk-20').removeAttr('Checked');
    }

    submitForm(e) {
        e.preventDefault();
        e.stopPropagation();

        let submittedForm = $(e.target);
        let formID = submittedForm.attr('id');


        if(formID !== "undefined")
        {
            let type = submittedForm.attr('method').toUpperCase();
            let parameters = submittedForm.serialize();
            submittedForm.find('.form-group.has-error').removeClass('has-error');
            submittedForm.find('p.error-msg').remove();
            //console.log(parameters);


            //let formUrl = window.location.origin + '/api/v1/formApi/form-data'; // link to specto custom validation


            $.ajax({
                url: window.location.origin + '/api/v1/formApi/form-data',
                type: type,
                data: parameters,
                success: function(response) {
                    submittedForm.next('.thank-you-form').show();
                    submittedForm.remove();
                    //console.log("SUCCESS");
                },
                error: function(response) {
                    $.each(response.responseJSON.msg, function () {
                        $('[id^=' + this.field + ']').closest('.form-group').addClass('has-error');
                    });

                    if(submittedForm.find('.form-group.has-error').length > 0) {
                        $('html, body').animate({
                            scrollTop: submittedForm.find('.form-group.has-error').first().offset().top - 100
                        }, 500);
                    }

                    if($("body").hasClass("sl-page")){
                        $(".contact-form .btn").before("<p class='error-msg'>Obvezna polja morajo biti pravilno izpolnjena.</p>");
                    } else {
                        $(".contact-form .btn").before("<p class='error-msg'>All fields are required.</p>");
                    }

                }
            });
        }
    }

    static ajaxLoad() {
        $(document).ajaxStart(function () {
            $("#ajaxLoader").show();
        });
        $(document).ajaxStop(function () {
            $("#ajaxLoader").hide();
        });
    }

}